// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default function ImgGallery ({images}) {
  return (
    <Swiper
      // install Swiper modules
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={50}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      loop

    >
        {

            images.map((img=>(
                <SwiperSlide> <img
                      src={img.src}
                      alt="lehel"
                      style={{
                        height: `600px`,
                        width: "100%",
                        objectFit: "contain",
                        objectPosition: img.position,
                      }}
                    /></SwiperSlide>

            )))
        }

      ...
    </Swiper>
  );
};