import { Container, Typography, Box, Divider } from "@mui/material";
import AutoplaySlideshow from "./ImgGallery";

import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";

import "./App.css";

let theme = createTheme();
theme = responsiveFontSizes(theme);

function App() {
  const images = [
    { src: `${process.env.PUBLIC_URL}/1.jpg`, position: "center" },
    { src: `${process.env.PUBLIC_URL}/2.jpg`, position: "center" },
    { src: `${process.env.PUBLIC_URL}/3.jpg`, position: "center" },
    { src: `${process.env.PUBLIC_URL}/4.jpg`, position: "center" },
    { src: `${process.env.PUBLIC_URL}/5.jpg`, position: "center" },
    { src: `${process.env.PUBLIC_URL}/6.jpg`, position: "center" },
    { src: `${process.env.PUBLIC_URL}/7.jpg`, position: "center" },
    { src: `${process.env.PUBLIC_URL}/8.jpg`, position: "center" },
  ];
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Box
          sx={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/1.jpg)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            minHeight: "70vh",
          }}
        >
          
        </Box>
        
        <Container >
        <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-end",
              mt: -5,
              background: "whitesmoke",
              borderRadius: "30px",mb: 10,
              pt: 2
            }}
          >
            <Typography
              variant="h3"
              sx={{
                lineheight: 1.167,
                letterSpacing: "0.2em",
                fontWeight: 900,
                pb: 1,
                color: "rgb(15, 52, 67)",
                textTransform: "uppercase"
              //   backgroundImage: "rgb(15, 52, 67)",
              // backgroundClip: "text",
              // textFillColor: "transparent"
              }}
            >
              1134 Budapest
            </Typography>
            <Typography
              variant="h3"
              sx={{
                lineheight: 1.167,
                letterSpacing: "0.2em",
                fontWeight: 900,
                pb: 1,
                color: "rgb(15, 52, 67)",
                textTransform: "uppercase"

              //   backgroundImage: "rgb(15, 52, 67)",
              // backgroundClip: "text",
              // textFillColor: "transparent"
              }}
            >
              Lehel utca 19.
            </Typography>
          </Box>
          <Typography
            sx={{
              my: 4,
              textAlign: "justify",
              textJustify: "inter-word",
              px: 3,
              color: "rgb(15, 52, 67)"

            }}
          >
            Tulajdonostól eladó a XIII. Kerületben, Angyalföldön 3. emeleti
            társasházi tégla lakás. A lakás 2022-ben felújításon esett át, mely
            során megújult a teljes elektromos hálózat, a fűtés korszerűsítés,
            valamint új konyha került beépítésre. Emellett a mellékhelység
            teljes felújítása is megtörtént. A fürdőszoba káddal felszerelt,
            mely külön helyiségben található a WC-től. A kellemes hőmérsékletről
            pedig két hűtő-fűtő klíma gondoskodik. A lakás világos, jó
            elrendezésű.
          </Typography>
          <Divider
            variant="fullWidth"
            sx={{ borderColor: "rgba(0, 0, 0, 0.3)" }}
          />
          <Typography
            sx={{
              my: 4,
              textAlign: "justify",
              textJustify: "inter-word",
              px: 3,
              color: "rgb(15, 52, 67)"

            }}
          >
            Az ingatlan kiváló elhelyezkedésű, mindössze néhány perces sétával
            elérhetőek éttermek, kávézók, bevásárlási lehetőségek, valamint a
            WestEnd és a Lehel piac. A környék csendes és biztonságos, a
            tömegközlekedési lehetőségek kiválóak, így könnyen elérhetőek a
            fontosabb közlekedési csomópontok.
          </Typography>
          <Divider
            variant="fullWidth"
            sx={{ borderColor: "rgba(0, 0, 0, 0.3)" }}
          />

          <Typography
            sx={{
              my: 4,
              textAlign: "justify",
              textJustify: "inter-word",
              px: 3,
                color: "rgb(15, 52, 67)"
            }}
          >
            A parkolás fizető övezetben lehetséges (hétköznapokon 8-22 óra
            között), azonban kerületi matricával megoldható. Az ingatlan 1/1
            tulajdonú, per és tehermentes, azonnal költözhető. Befektetésnek is
            kiváló lehetőség, hiszen a jó lokációnak köszönhetően könnyen
            kiadható.
          </Typography>
          <Divider
            variant="fullWidth"
            sx={{ borderColor: "rgba(0, 0, 0, 0.3)" }}
          />

          <Typography
            sx={{
              my: 4,
              textAlign: "justify",
              textJustify: "inter-word",
              px: 3,
                color: "rgb(15, 52, 67)"
            }}
          >
            Kérem, ingatlanosok NE keressenek!
          </Typography>
          <Divider
            variant="fullWidth"
            sx={{ borderColor: "rgba(0, 0, 0, 0.3)" }}
          />
          <Typography
            variant="body1"
            sx={{
              textAlign: "justify",
              textJustify: "inter-word",
              my: 4,
              px: 3,
                color: "rgb(15, 52, 67)",
                mb: 6
            }}
          >
            Telefon: <a href="tel:+36205590387">06 20 559 0387</a>
          </Typography>
          <Box sx={{ my: 4, pb: 8 }}>
            <AutoplaySlideshow images={images} />
          </Box>
        </Container>
      </div>
    </ThemeProvider>
  );
}

export default App;
